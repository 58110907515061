<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="true" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">
                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        />

        <component
            :is="collection"
            :status="status"
            :search="search"
            @change_loaddata_status="onLoadDataStatusChanged"
            ref="currentComponent"
        />

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import Adjusted from './lists/Adjusted.vue';
import Executed from './lists/Executed.vue';
import Inspected from './lists/Inspected.vue';
import Signed from './lists/Signed.vue';

export default {
    name: "HandledProxy",
    components: {
        Toolbar,
        FilterPanel,
        "Documents.My.Adjusted": Adjusted,
        "Documents.My.Executed": Executed,
        "Documents.My.Inspected": Inspected,
        "Documents.My.Signed": Signed
    },
    data () {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null,
            dataloading:false,
            filterloading:false,
            collectionloading:false,
        }
    },
    computed: {
        filterloadingcomp:{
            get: function() { return this.filterloading; },
            set: function(newValue) { this.filtreloading = newValue; this.refreshOverlay(); }
        },
        collectionloadingcomp:{
            get: function() { return this.collectionloading; },
            set: function(newValue) { this.collectionloading = newValue; this.refreshOverlay(); }
        },
        collection: {
            get: function() {
                return this.$store.getters['handled/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('handled/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['handled/getStatus'];
            },
            set: function(newValue) {
                this.$store.commit('handled/SET_STATUS', newValue);

                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }
            }
        },
        search: {
            get: function() {
                var searches = this.$store.getters['handled/getSearches'];

                if (!searches) return "";
                
                return searches.find(x => x.collection == this.collection)?.value ?? "";
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }
                this.$store.commit('handled/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onLoadDataStatusChanged(status){
            this.dataloading = status;
            this.refreshOverlay();    
        },
        refreshOverlay(){
            this.setOverlayVisible({ visible: this.dataloading || this.filterloading || this.collectionloading });
        },
        onToolbarClick(event, btn) {
            this[btn.Action]();
        },
        async loadCollectionData () {
            this.collectionloadingcomp = true;
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/actions/collection?type=${this.collection}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.payload;
            }
            this.collectionloadingcomp = false;
        },
        async loadFilters() {
            this.filterloadingcomp = true;
            let filterResponse = await httpAPI({
                url: `/api/handled/filter`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['auth/getUserInfo'])?.permissions ?? [];
                filterResponse.data.payload.filter.items = filterResponse.data.payload.filter.items.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                this.filterDataSource = filterResponse.data.payload;
            }
            this.filterloadingcomp = false;
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        }
    },
    watch: {
        collection : {
            handler () {
                this.loadCollectionData();
            }
        }
    },
    async created () {
        try
        {
            await this.loadCollectionData();
        }
        catch
        {
            this.setOverlayVisible({ visible: false });
        }
    }
}
</script>