<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :item-class="itemRowBackground"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document', common: data.item.iscommon })">
                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.regdate`]="{ item }">
                    {{ formatDate(item.regdate) }}
                </template>

                <template v-slot:[`item.correspondent`]="{ item }">
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.correspondent">
                            {{item.correspondent}}
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.theme`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <v-common-chip v-if="item.iscommon === true && item.isown === false" :document-type="item.doctypeof"/>
                        <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.theme">
                                {{item.theme}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.signdate`]="{ item }">
                    {{ formatDate(item.signdate) }}
                </template>

                <template v-slot:[`item.statuscode`]="{ item }">
                    <StatusChip :status="GetAvaibleStatuses[$helpers.getAdjustmentStatus(item.statuscode)]"/>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>
                
            </v-data-table>
        </v-col>

    </v-row>

</template>

<script>
import i18n from '@/i18n'
import { mapGetters } from 'vuex'
import StatusChip from '@/components/StatusChip'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";

export default {
    columnIndex:  {
        'theme': 3,
        'correspondent': 4,
        'signdate': 5,
        'statuscode': 6,
        'regnumber': 7,
        'regdate': 8
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    components: {
        StatusChip
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Номер"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Корреспондент_Получатель"), value: 'correspondent', width: '20%' },
                { text: i18n.t("Краткое_содержание"), value: 'theme' },
                { text: i18n.t("Дата_согласования_сокр"), value: 'signdate', width: '150px' },
                { text: i18n.t("Статус"), value: 'statuscode', sortable: false, width: '120px' }
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        ...mapGetters('references', ['GetAvaibleStatuses']),
        options: {
            get: function() {
                return this.$store.getters['handled/adjustedlist/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('handled/adjustedlist/SET_TABLE_OPTION', newValue);
            }
        },
        collection () {
            return this.$store.getters['handled/getCollection'];
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search
            }
        },
    },
    methods: {
        resetPage() {
            this.$store.commit('handled/adjustedlist/SET_TABLE_OPTION_PAGE', 1);
        },
        itemRowBackground (item) {
            return item.readed !== false ? '' : 'font-weight-bold';
        },        
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            this.$emit("change_loaddata_status",this.loading);
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `/api/handled/list?collection=MyDocuments.Adjusted&filter=${this.getTableFilterObject()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;

            this.loading = false;
            this.$emit("change_loaddata_status",this.loading);
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 8;

            return JSON.stringify({ SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage });
        },
        formatDate (value) {
            if (!value)
                return '';

            return this.$moment(value).format('DD.MM.YYYY');
        }
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>